import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";

import { Button, ButtonProps, Label, Modal } from "semantic-ui-react";

import moment from "moment";

// Common
import ModInfo from "react-lib/apps/common/ModInfo";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import { alignRight } from "react-lib/apps/common/PureReactTable";
import ButtonLoadCheck, { BLClickHandler } from "react-lib/appcon/common/ButtonLoadCheck";

// UX
import CardMedicalFee from "../BIL/CardMedicalFee";
import CardPatientSearchBox from "../TPD/CardPatientSearchBox";
import ModalSearchEncounter from "../ADM/ModalSearchEncounter";

import CardCreateInvoiceGroupUX from "./CardCreateInvoiceGroupUX";
import CardPatientExpenseItemInfoUX from "./CardPatientExpenseItemInfoUX";
import CardSearchInvoiceGroup from "./CardSearchInvoiceGroup";
import ModInputDateForCreate, { SaveEventHandler } from "./ModInputDateForCreate";

// Interface
import {
  ACTIONS,
  BTN_ACTS,
  BillTransactionListSerializer,
  CARD_CREATE_INV_GROUP,
  FilterAllKeys,
  FilterAllValues,
  MasterOptionsType,
  PickedProps,
  RunSequence,
  SetProp,
  State,
} from "./sequence/ARInvoiceGroup";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";

// Types
type CardCreateInvoiceGroupProps = {
  onEvent: (e: any) => any;
  setProp: SetProp;
  // controller
  drugOrderQueueController: any;
  // seq
  runSequence: RunSequence;
  ARInvoiceGroupSequence: State["ARInvoiceGroupSequence"];
  BillPaymentSequence?: any;
  // options
  coveragePayerOptions: State["coveragePayerOptions"];
  masterOptions?: MasterOptionsType;
  // CommonInterface
} & PickedProps;

type TabMenuKeys = keyof typeof INV_GROUP;

// Const
const BILL_STATUS_COLORS = {
  ชำระเงินแล้วเรียบร้อย: "green",
  ยกเลิก: "red",
  รอรับชำระเงิน: "orange",
};

const INV_GROUP = {
  InvIssued: {
    buttons: ["print_report", "download_excel"] as string[],
    data: "invIssuedBillTransaction",
    filter: "filterInvIssued",
    title: "รายการที่ออกใบแจ้งหนี้แล้ว",
  },
  IssueInv: {
    buttons: ["create_ar", "create_ar_transaction"] as string[],
    data: "issueInvBillTransaction",
    filter: "filterIssueInv",
    title: "ออกใบแจ้งหนี้เรียกเก็บเงินต้นสังกัด",
  },
} as const;

const CardCreateInvoiceGroup = (props: CardCreateInvoiceGroupProps) => {
  const intl = useIntl();
  // Mod
  const [modGenerateAR, setModGenerateAR] = useState<boolean>(false);
  const [modPatientExpense, setModPatientExpense] = useState<Record<string, any> | null>(null);
  const [openModError, setOpenModError] = useState<boolean>(false);
  // Check
  // #const [checkedIds, setCheckedIds] = useState<number[]>([]);

  const [activeTab, setActiveTab] = useState<TabMenuKeys>("IssueInv");

  // Effect
  useEffect(() => {
    setActiveTab("IssueInv");

    props.runSequence({
      sequence: "ARInvoiceGroup",
      nextIndex: "IssueInv",
      restart: true,
    });

    return () => {
      props.runSequence({ sequence: "ARInvoiceGroup", clear: true });
    };
  }, []);

  // Memo callback
  const filterIssueInv = useMemo(
    () => props.ARInvoiceGroupSequence?.filterIssueInv,
    [props.ARInvoiceGroupSequence?.filterIssueInv]
  );

  const filterInvIssued = useMemo(
    () => props.ARInvoiceGroupSequence?.filterInvIssued,
    [props.ARInvoiceGroupSequence?.filterInvIssued]
  );

  // Callback
  const allowSearch = useCallback(() => {
    if (activeTab === "IssueInv") {
      return !!filterIssueInv?.coveragePayer;
    }

    return true;
  }, [activeTab, filterIssueInv]);

  const formatPrice = useCallback((inputNumber?: string) => {
    const number = (inputNumber || "0").toString();

    return Number.parseFloat(number).toLocaleString("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: "decimal",
    });
  }, []);

  const handleOpenModPatientExpense = useCallback(
    (data: BillTransactionListSerializer) => () => {
      props.onEvent({
        message: "GetCommonBillTransaction",
        params: { hisReference: data.his_reference || data.id },
      });

      setModPatientExpense(data);
    },
    []
  );

  // #const handleChecked = useCallback(
  //   (e: any, data: any) => {
  //     let ids = [...checkedIds];
  //     if (data.checked) {
  //       ids = [...ids, data.name];
  //     } else {
  //       ids = ids.filter((id) => id !== data.name);
  //     }

  //     setCheckedIds(ids);
  //   },
  //   [checkedIds]
  // );

  const commonBillTransaction = useMemo(
    () => ({
      ...props.commonBillTransaction,
      patient_name: modPatientExpense?.name,
    }),
    [modPatientExpense?.name, props.commonBillTransaction]
  );

  // Memo
  const tableDetail = useMemo(() => {
    const data = props.ARInvoiceGroupSequence?.[INV_GROUP[activeTab].data];
    const items = data?.items || [];

    const summary = Object.fromEntries(
      Object.entries(data?.summary || {}).map(([key, value]) => {
        const formattedValue = typeof value === "number" ? value : formatPrice(value);

        return [key, formattedValue];
      })
    );

    return {
      data: INV_GROUP[activeTab],
      items: items.map((item) => {
        const coveragePayerName = item.sent_claim_coverage_code
          ? `[${item.sent_claim_coverage_code}] ${item.sent_claim_coverage_name}`
          : "";

        const payerName = item.sent_claim_payer_code
          ? `[${item.sent_claim_payer_code}] ${item.sent_claim_payer_name}`
          : "";

        return {
          ...item,
          // checked: (
          //   <div style={{ display: "grid", placeContent: "center" }}>
          //     <Checkbox
          //       name={item.id}
          //       checked={checkedIds.includes(item.id)}
          //       // onChange={handleChecked}
          //     />
          //   </div>
          amount: alignRight(formatPrice(item.amount || item.total_amount_price)),
          claim_amount: alignRight(formatPrice(item.claim_amount || item.total_send_claim_price)),
          coverage_payer: coveragePayerName,
          detail: (
            <Button
              color="orange"
              content={intl.formatMessage({ id: "รายละเอียดค่าใช้จ่าย" })}
              size="mini"
              style={{ paddingLeft: "0.25rem", paddingRight: "0.25rem" }}
              onClick={handleOpenModPatientExpense(item)}
            />
          ),
          dt_tran: item.dt_tran ? formatDatetime(moment(item.dt_tran), true) : "",
          encounter_id: item.encounter_id || item.encounter,
          paid: alignRight(formatPrice(item.paid || item.total_paid_price)),
          payer: payerName,
          pid: item.pid || item.personal_id,
        };
      }),
      summary,
    };
  }, [
    activeTab,
    props.ARInvoiceGroupSequence?.invIssuedBillTransaction,
    props.ARInvoiceGroupSequence?.issueInvBillTransaction,
  ]);

  // #const summary = useMemo(() => {
  // #const items: any[] = (props.ARInvoiceGroupSequence?.billTransactionList || []).filter(
  //   (item: any) => checkedIds.includes(item.id)
  // );
  // const keyValues = [
  //   ["total_sent_claim_price", "claim_amount"],
  //   ["total_paid_price", "paid"],
  //   ["total_other_pay_price", "other_pay"],
  // ];
  // const sum = items.reduce((result, item) => {
  //   return keyValues.reduce(
  //     (acc, [key, value]) => ({
  //       ...acc,
  //       [key]: Number(result[key] || 0) + Number(item[value]),
  //     }),
  //     {} as any
  //   );
  // }, {});

  // return {} as any; //{ ...sum, total_rows: items.length };
  // }, [checkedIds]);

  // #const mapOptions = (items: any) => {
  //   return items.map((item: any) => ({
  //     key: item?.id,
  //     value: item?.id,
  //     text: `${item.lot_no}-${item.fiscal_year} ${item.payer_code_name}`,
  //   }));
  // };

  const handleOnCreateAR = () => {
    setModGenerateAR(true);
  };

  const handleCloseModal = () => {
    setModPatientExpense(null);

    props.setProp("commonBillTransaction", {});
  };

  const handleCreate: BLClickHandler<typeof ACTIONS.CREATE_AR_TRANSACTION> = (e, data) => {
    // #if (!checkedIds.length) {
    //   return setOpenModError(true);
    // }
    props.runSequence({
      sequence: "ARInvoiceGroup",
      action: data.name,
      card: CARD_CREATE_INV_GROUP,
      filterKey: tableDetail.data.filter,
      stateKey: tableDetail.data.data,
    });
  };

  const handleCloseModSuccess = () => {
    props.setProp(`successMessage.${BTN_ACTS.CREATE_AR_TRANSACTION}`, null);
  };

  const handleCloseModError = () => {
    setOpenModError(false);
  };

  const handleChangeTab = (e: SyntheticEvent, data: { name: TabMenuKeys } & ButtonProps) => {
    setActiveTab(data.name);

    props.setProp(`buttonLoadCheck.${BTN_ACTS.SEARCH}`, null);
    props.setProp(`errorMessage.${CARD_CREATE_INV_GROUP}`, null);

    props.runSequence({
      sequence: "ARInvoiceGroup",
      nextIndex: data.name,
    });
  };

  const handleChangeFilter = (
    e: SyntheticEvent | null,
    data: { checked?: boolean; name: FilterAllKeys; value: FilterAllValues }
  ) => {
    const value = data.checked === undefined ? data.value : data.checked;

    props.setProp(`ARInvoiceGroupSequence.${data.name}`, value);
  };

  const handleSearch: BLClickHandler<typeof ACTIONS.SEARCH> = (e, data) => {
    props.runSequence({
      sequence: "ARInvoiceGroup",
      action: data.name,
      filterKey: tableDetail.data.filter,
      stateKey: tableDetail.data.data,
    });
  };

  const handleChangePatientIssueInv = (id: number | null) => {
    handleChangeFilter(null, { name: "filterIssueInv.patientId", value: id });
  };

  const handleChangePatientInvIssued = (id: number | null) => {
    handleChangeFilter(null, { name: "filterInvIssued.patientId", value: id });
  };

  const handleGetEncounterList = async (params: Record<string, any>) =>
    props.onEvent({
      message: "GetEncounterList",
      params,
    });

  const handleSelectAr = (data: Record<string, any> | null) => {
    const seq = props.ARInvoiceGroupSequence;

    props.setProp("ARInvoiceGroupSequence", {
      ...seq,
      filterInvIssued: { ...seq?.filterInvIssued, arTransaction: data },
      ...(!data && { invIssuedBillTransaction: { items: [], summary: {} } }),
    });
  };

  const handleSelectEnInvIssued = (data: Record<string, any>) => {
    handleChangeFilter(null, { name: "filterInvIssued.encounterId", value: data.id });
  };

  const handleClearEnInvIssued = () => {
    handleChangeFilter(null, { name: "filterInvIssued.encounterId", value: null });
  };

  const handleSelectEnIssueInv = (data: Record<string, any>) => {
    handleChangeFilter(null, { name: "filterIssueInv.encounterId", value: data.id });
  };

  const handleClearEnIssueInv = () => {
    handleChangeFilter(null, { name: "filterIssueInv.encounterId", value: null });
  };

  const handleCloseModGenerateAR = () => {
    setModGenerateAR(false);
  };

  const handleSaveGenerateAR: SaveEventHandler = (data) => {
    const { coveragePayer, date } = data.data;

    props.runSequence({
      sequence: "ARInvoiceGroup",
      action: ACTIONS.CREATE_AR,
      btnAction: data.btnAction,
      callback: handleCloseModGenerateAR,
      coveragePayerId: coveragePayer,
      date,
    });
  };

  const handleDownloadExcel: BLClickHandler<typeof ACTIONS.DOWNLOAD_EXCEL> = (e, data) => {
    props.runSequence({
      sequence: "ARInvoiceGroup",
      action: data.name,
      card: CARD_CREATE_INV_GROUP,
    });
  };

  const handlePrintReport: BLClickHandler<typeof ACTIONS.PRINT_REPORT> = (e, data) => {
    props.runSequence({
      sequence: "ARInvoiceGroup",
      action: data.name,
      card: CARD_CREATE_INV_GROUP,
    });
  };

  const handleClose = () => {
    props.setProp(`errorMessage.${CARD_CREATE_INV_GROUP}`, null);
  };

  // #console.log("CardCreateInvoiceGroup", props);

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage?.[CARD_CREATE_INV_GROUP]}
        success={null}
        onClose={handleClose}
        languageUX={props.languageUX}
      />

      <CardCreateInvoiceGroupUX
        // data
        activeTab={activeTab}
        billingTransactionItems={tableDetail.items}
        filterInvIssued={filterInvIssued}
        filterIssueInv={filterIssueInv}
        summary={tableDetail.summary}
        // table
        table={tableDetail.data}
        // options
        coveragePayerOptions={props.coveragePayerOptions}
        payerOptions={props.masterOptions?.payer}
        // callback
        onChangeFilter={handleChangeFilter}
        // callback
        onChangeTab={handleChangeTab}
        onCreateAR={handleOnCreateAR}
        // Component
        buttonCreate={
          <ButtonLoadCheck
            setProp={props.setProp}
            color={"green"}
            disabled={!props.ARInvoiceGroupSequence?.filterIssueInv?.coveragePayer}
            name={ACTIONS.CREATE_AR_TRANSACTION}
            paramKey={BTN_ACTS.CREATE_AR_TRANSACTION}
            size="small"
            title={intl.formatMessage({ id: "สร้างเลขที่ใบแจ้งหนี้" })}
            buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.CREATE_AR_TRANSACTION]}
            // callback
            onClick={handleCreate}
          />
        }
        buttonDownloadExcel={
          <ButtonLoadCheck
            setProp={props.setProp}
            color={"yellow"}
            disabled={!props.ARInvoiceGroupSequence?.filterInvIssued?.arTransaction}
            name={ACTIONS.DOWNLOAD_EXCEL}
            paramKey={BTN_ACTS.DOWNLOAD_EXCEL}
            size="small"
            title={intl.formatMessage({ id: "ดาวน์โหลด xlsx." })}
            buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.DOWNLOAD_EXCEL]}
            // callback
            onClick={handleDownloadExcel}
          />
        }
        buttonPrint={
          <ButtonLoadCheck
            setProp={props.setProp}
            color={"teal"}
            disabled={!props.ARInvoiceGroupSequence?.filterInvIssued?.arTransaction}
            name={ACTIONS.PRINT_REPORT}
            paramKey={BTN_ACTS.PRINT_REPORT}
            size="small"
            title={intl.formatMessage({ id: "พิมพ์เอกสาร" })}
            buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.PRINT_REPORT]}
            // callback
            onClick={handlePrintReport}
          />
        }
        buttonSearch={
          <ButtonLoadCheck
            setProp={props.setProp}
            color={"blue"}
            disabled={!allowSearch()}
            name={ACTIONS.SEARCH}
            paramKey={BTN_ACTS.SEARCH}
            title={intl.formatMessage({ id: "ค้นหา" })}
            buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.SEARCH]}
            // callback
            onClick={handleSearch}
          />
        }
        invIssuedPatientSearchBox={
          <CardPatientSearchBox
            // controller
            controller={props.drugOrderQueueController}
            // config
            disabled={!filterInvIssued?.isPatient}
            // callback
            onEnterPatientSearch={handleChangePatientInvIssued}
            languageUX={props.languageUX}
          />
        }
        invIssuedSearchEncounter={
          <ModalSearchEncounter
            disabledSearch={!filterInvIssued?.isEncounter}
            patientId={filterIssueInv?.patientId}
            textField="id"
            fluid
            readOnly
            onClearInput={handleClearEnInvIssued}
            onGetEncounter={handleGetEncounterList}
            onSelect={handleSelectEnInvIssued}
          />
        }
        issueInvPatientSearchBox={
          <CardPatientSearchBox
            // controller
            controller={props.drugOrderQueueController}
            // config
            disabled={!filterIssueInv?.isPatient}
            // callback
            onEnterPatientSearch={handleChangePatientIssueInv}
            languageUX={props.languageUX}
          />
        }
        issueInvSearchEncounter={
          <ModalSearchEncounter
            disabledSearch={!filterIssueInv?.isEncounter}
            patientId={filterIssueInv?.patientId}
            textField="id"
            fluid
            readOnly
            onClearInput={handleClearEnIssueInv}
            onGetEncounter={handleGetEncounterList}
            onSelect={handleSelectEnIssueInv}
            languageUX={props.languageUX}
          />
        }
        searchInvoiceGroup={
          <CardSearchInvoiceGroup
            onEvent={props.onEvent}
            setProp={props.setProp}
            buttonLoadCheck={props.buttonLoadCheck}
            // callback
            onSelected={handleSelectAr}
            languageUX={props.languageUX}
          />
        }
        languageUX={props.languageUX}
      />

      <ModInputDateForCreate
        onEvent={props.onEvent}
        setProp={props.setProp}
        coveragePayer={filterIssueInv?.coveragePayer}
        // data
        open={modGenerateAR}
        // CommonInterface
        buttonLoadCheck={props.buttonLoadCheck}
        errorMessage={props.errorMessage}
        successMessage={props.successMessage}
        // options
        coveragePayerOptions={props.coveragePayerOptions || []}
        // callback
        onClose={handleCloseModGenerateAR}
        onSave={handleSaveGenerateAR}
      />

      <Modal
        open={!!modPatientExpense}
        size="fullscreen"
        closeOnDimmerClick
        onClose={handleCloseModal}
      >
        <CardPatientExpenseItemInfoUX
          // data
          data={commonBillTransaction}
          // callback
          onClose={handleCloseModal}
          // Element
          medicalFee={
            <CardMedicalFee
              onEvent={props.onEvent}
              setProp={props.setProp}
              BillPaymentSequence={props.BillPaymentSequence}
              // Bill Report Print
              billReportPrint={props.billReportPrint}
              encounterId={props.commonBillTransaction?.encounter_id}
              invoiceItemByItems={props.invoiceItemByItems}
              invoiceItemByModes={props.invoiceItemByModes}
              invoiceItemByOrders={props.invoiceItemByOrders}
              languageUX={props.languageUX}
              patientInfo={{}}
              receiptId={props.commonBillTransaction?.receipt}
              // Hide แจ้งชำระเงิน และ รับชำระเงิน button
              hidebp
              hidebpInvoice
              isOnlyPaid
              // config
              viewMode
              errorMessage={props.errorMessage}
            />
          }
          // Element
          status={
            <Label
              style={{ marginRight: "2rem" }}
              color={
                (BILL_STATUS_COLORS as any)[
                  props.commonBillTransaction?.encounter_payment_status || ""
                ]
              }
            >
              {props.commonBillTransaction?.encounter_payment_status || ""}
            </Label>
          }
          languageUX={props.languageUX}
        />
      </Modal>

      <ModInfo
        open={!!props.successMessage?.[BTN_ACTS.CREATE_AR_TRANSACTION]}
        style={{ width: "30%" }}
        titleColor={"green"}
        titleName={intl.formatMessage({ id: "สร้างเลขที่ใบแจ้งหนี้สำเร็จ" })}
        onApprove={handleCloseModSuccess}
        onClose={handleCloseModSuccess}
      >
        <div style={{ alignItems: "center", display: "flex", padding: "1rem 0" }}>
          <span
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
              minWidth: "max-content",
              paddingRight: "10px",
            }}
          >
            เลขที่ใบแจ้งหนี้
          </span>
          <Label
            size="large"
            style={{
              color: "rgba(0,0,0,0.87)",
              fontSize: "1.1rem",
              width: "100%",
            }}
          >
            {props.successMessage?.[BTN_ACTS.CREATE_AR_TRANSACTION]}
          </Label>
        </div>
      </ModInfo>

      <ModInfo
        open={openModError}
        titleColor={"red"}
        titleName={intl.formatMessage({ id: "แจ้งเตือน" })}
        onApprove={handleCloseModError}
        onClose={handleCloseModError}
      >
        <div style={{ padding: "0.5rem 0" }}>
          <span style={{ fontSize: "1.1rem", paddingRight: "10px" }}>
            กรุณาเลือกรายการที่ต้องการสร้างเลขที่ใบแจ้งหนี้
          </span>
        </div>
      </ModInfo>
    </div>
  );
};

CardCreateInvoiceGroup.displayName = "CardCreateInvoiceGroup";

export default React.memo(CardCreateInvoiceGroup);
